<template>
  <div :id="advanced?.id ?? ''"
    :class="`grid-${layout} -${container.width} ${height ? `-${height}` : ''} ${advanced?.class ?? ''}`">
    <GridArea v-for="i in areasCount" :i="i - 1" :width="calculateWithFromLayout(i)">
      <div v-for="block in areas[i - 1]" :key="block.id" class="blocks_block">
        <Block :block="block" />
      </div>
    </GridArea>
  </div>
</template>

<script setup lang="ts">
import './grid.css'
import { __unique } from '@lotsof/sugar/array'

import { type TGrid } from './grid.type.ts'
import Block from '../block/block.vue'
import GridArea from '~/components/gridArea/gridArea.vue'

const props = withDefaults(defineProps<TGrid>(), {
  layout: '12',
})

function calculateWithFromLayout(i: number) {
  const columnsCount = __unique(props.layout.split('')).length
  let areaCountInRow = 0
  const rows = props.layout.split('_')
  for (let [j, row] of rows.entries()) {
    const areas = row.split('').filter((r) => r !== ' ')
    const countInRow = areas.filter((a) => {
      return parseInt(a) == i
    })
    if (countInRow.length > areaCountInRow) {
      areaCountInRow = countInRow.length
    }
  }
  return Math.round((100 / columnsCount) * areaCountInRow)
}

const areasCount = __unique(props.layout?.split('')).length
const areas = [
  props.area1,
  props.area2,
  props.area3,
  props.area4,
  props.area5,
  props.area6,
]
</script>